/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.6.1-v202501132122-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface SupportRequestInputV1 {
    "attachment"?: models.AttachmentInputV1;
    "attachmentForJira"?: models.JiraAttachment;
    /**
     * Additional details that can help the Support Team understand the problem or request
     */
    "description": string;
    /**
     * The email address of the sender
     */
    "email": string;
    /**
     * The observed frequency of the problem
     */
    "frequency": FrequencyEnum;
    /**
     * The name of the sender
     */
    "name": string;
    /**
     * The severity level, S4 is the lowest and most common and S1 is the highest and for system down events
     */
    "severity": SeverityEnum;
    /**
     * The steps to reproduce the problem
     */
    "stepsToReproduce"?: string;
    /**
     * A one-line description of the problem or feature request
     */
    "summary": string;
}

export enum FrequencyEnum {
    NotApplicable = 'NotApplicable' as any,
    Once = 'Once' as any,
    EveryTime = 'EveryTime' as any,
    Infrequently = 'Infrequently' as any
}
export enum SeverityEnum {
    S4 = 'S4' as any,
    S3 = 'S3' as any,
    S2 = 'S2' as any,
    S1 = 'S1' as any
}
